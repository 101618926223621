import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  Action,
  Actions,
  Selector,
  State,
  StateContext,
  ofAction,
} from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import {
  catchError,
  finalize,
  lastValueFrom,
  takeUntil,
  tap,
  throwError,
} from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  BASE_STATE_DEFAULTS,
  BaseStateModel,
} from '../../interfaces/base-state-model.interface';
import { mapCreatedUpdated } from '../../interfaces/base.interface';
import {
  PaginationQuery,
  PaginationResponse,
  sanitizePaginationQuery,
} from '../../interfaces/pagination.interface';
import { delayRequest } from '../../shared/util/delayed-request.util';
import { Employee } from '../interface/employee.interface';

type EmployeeWithDeletionDate = Employee & { deletionDate: Date };
interface EmployeeCounts {
  total: number;
  active: number;
  inactive: number;
  admins: number;
  history: {
    created: Date;
    activeCount: number;
    inactiveCount: number;
    adminCount: number;
  }[];
}

export class LoadEmployees {
  static readonly type = '[Employees] Load Employees';
  constructor(public paginationQuery?: PaginationQuery) {}
}

export class CreateEmployee {
  static readonly type = '[Employees] Create Employee';
  constructor(public employee: Employee) {}
}

export class UpdateEmployee {
  static readonly type = '[Employees] Update Employee';
  constructor(public employee: Employee) {}
}

export class DisassociateEmployee {
  static readonly type = '[Employees] Disassociate Employee';
  constructor(public employee: Employee) {}
}

export class DeleteEmployee {
  static readonly type = '[Employees] Delete Employee';
  constructor(public employee: Employee) {}
}

export class LoadAllEmployeeNumbers {
  static readonly type = '[Employees] Load All Employee Numbers';
}

export class LoadEmployeesPendingDeletion {
  static readonly type = '[Employees] Load Employees Pending Deletion';
}

export class LoadEmployeesCounts {
  static readonly type = '[Employees] Load Employees Counts';
}

export interface EmployeesStateModel extends BaseStateModel {
  employees: Employee[];
  pagingMeta?: PaginationResponse<Employee[]>['meta'];
  allEmployeeNumbers: string[];
  pendingDeletion: EmployeeWithDeletionDate[];
  counts: EmployeeCounts & { lastUpdated: Date | null };
}

@State<EmployeesStateModel>({
  name: 'employees',
  defaults: {
    ...BASE_STATE_DEFAULTS,
    employees: [],
    pagingMeta: undefined,
    allEmployeeNumbers: [],
    pendingDeletion: [],
    counts: {
      total: 0,
      active: 0,
      inactive: 0,
      admins: 0,
      lastUpdated: null,
      history: [],
    },
  },
})
@Injectable()
export class EmployeesState {
  private http = inject(HttpClient);
  private actions$ = inject(Actions);

  @Selector()
  static employees(state: EmployeesStateModel) {
    return state.employees;
  }

  @Selector()
  static pagingMeta(state: EmployeesStateModel) {
    return state.pagingMeta;
  }

  @Selector()
  static loading(state: EmployeesStateModel) {
    return state.loading;
  }

  @Selector()
  static loaded(state: EmployeesStateModel) {
    return state.loaded;
  }

  @Selector()
  static error(state: EmployeesStateModel) {
    return state.error;
  }

  @Selector()
  static allEmployeeNumbers(state: EmployeesStateModel) {
    return state.allEmployeeNumbers;
  }

  @Selector()
  static pendingDeletion(state: EmployeesStateModel) {
    return state.pendingDeletion;
  }

  @Selector()
  static counts(state: EmployeesStateModel) {
    return state.counts;
  }

  @Action(LoadEmployees, {
    cancelUncompleted: true,
  })
  async loadEmployees(
    ctx: StateContext<EmployeesStateModel>,
    { paginationQuery }: LoadEmployees,
  ) {
    ctx.patchState({
      loading: true,
      error: null,
    });

    return this.http
      .get<PaginationResponse<Employee[]>>(
        `${environment.apiUrl}/v1/employees`,
        {
          params: paginationQuery && sanitizePaginationQuery(paginationQuery),
        },
      )
      .pipe(
        takeUntil(this.actions$.pipe(ofAction(LoadEmployees))),
        catchError((err) => {
          ctx.patchState({
            error: err,
          });
          return throwError(() => err);
        }),
        tap((pagedResponse) => {
          return ctx.patchState({
            employees: this.mapEmployees(pagedResponse.data),
            pagingMeta: pagedResponse.meta,
          });
        }),
        finalize(() => {
          ctx.patchState({
            loading: false,
            loaded: true,
          });
        }),
      );
  }

  @Action(CreateEmployee)
  async createEmployee(
    ctx: StateContext<EmployeesStateModel>,
    { employee }: CreateEmployee,
  ) {
    ctx.patchState({
      loading: true,
    });

    try {
      const savedEmployee = await delayRequest(
        lastValueFrom(
          await this.http.post<Employee>(
            `${environment.apiUrl}/v1/employees`,
            employee,
          ),
        ),
      );
      ctx.patchState({
        employees: [
          ...ctx.getState().employees,
          this.mapEmployee(savedEmployee),
        ],
      });
    } catch (err) {
      throw err;
    } finally {
      ctx.patchState({
        loading: false,
      });
    }
  }

  @Action(UpdateEmployee)
  async updateEmployee(
    ctx: StateContext<EmployeesStateModel>,
    { employee }: UpdateEmployee,
  ) {
    ctx.patchState({
      loading: true,
    });

    try {
      const savedEmployee = await delayRequest(
        lastValueFrom(
          this.http.put<Employee>(
            `${environment.apiUrl}/v1/employees/${employee.id}`,
            employee,
          ),
        ),
      );
      ctx.setState(
        patch({
          employees: updateItem(
            (employee) => savedEmployee?.id === employee?.id,
            this.mapEmployee(savedEmployee),
          ),
        }),
      );
    } catch (err) {
      throw err;
    } finally {
      ctx.patchState({
        loading: false,
      });
    }
  }

  @Action(DisassociateEmployee)
  async disassociateEmployee(
    ctx: StateContext<EmployeesStateModel>,
    { employee }: DisassociateEmployee,
  ) {
    ctx.patchState({
      loading: true,
    });

    try {
      const res = await delayRequest(
        lastValueFrom(
          this.http.post<Employee>(
            `${environment.apiUrl}/v1/employees/${employee.id}/disassociate`,
            {},
          ),
        ),
      );

      ctx.setState(
        patch({
          employees: updateItem<Employee>(
            (e) => e?.id === res.id,
            this.mapEmployee(res),
          ),
        }),
      );
    } catch (err) {
      throw err;
    } finally {
      ctx.patchState({
        loading: false,
      });
    }
  }

  @Action(DeleteEmployee)
  async deleteEmployee(
    ctx: StateContext<EmployeesStateModel>,
    action: DeleteEmployee,
  ) {
    ctx.patchState({
      loading: true,
    });

    try {
      await delayRequest(
        lastValueFrom(
          this.http.delete<Employee>(
            `${environment.apiUrl}/v1/employees/${action.employee.id}`,
          ),
        ),
      );

      ctx.patchState({
        employees: ctx
          .getState()
          .employees.filter((employee) => employee.id !== action.employee.id),
      });
    } catch (err) {
      throw err;
    } finally {
      ctx.patchState({
        loading: false,
      });
    }
  }

  @Action(LoadAllEmployeeNumbers)
  async loadAllEmployeeNumbers(ctx: StateContext<EmployeesStateModel>) {
    ctx.patchState({
      loading: true,
    });

    try {
      const res = await lastValueFrom(
        this.http.get<string[]>(
          `${environment.apiUrl}/v1/employees/employee-numbers`,
        ),
      );

      ctx.patchState({
        allEmployeeNumbers: res,
      });
    } catch (err) {
      ctx.patchState({
        error: err,
      });
      throw err;
    } finally {
      ctx.patchState({
        loaded: true,
        loading: false,
      });
    }
  }

  @Action(LoadEmployeesPendingDeletion)
  async loadEmployeesPendingDeletion(ctx: StateContext<EmployeesStateModel>) {
    ctx.patchState({ loading: true });
    try {
      const employees = await lastValueFrom(
        this.http.get<EmployeeWithDeletionDate[]>(
          `${environment.apiUrl}/v1/employees/pending-deletion`,
        ),
      );

      const mappedEmployees = this.mapEmployees<EmployeeWithDeletionDate>(
        employees,
      ).map((employee) => ({
        ...employee,
        deletionDate: new Date(employee.deletionDate),
      }));

      ctx.patchState({
        pendingDeletion: mappedEmployees,
      });
    } catch (err) {
      ctx.patchState({
        error: err,
      });
      throw err;
    } finally {
      ctx.patchState({
        loaded: true,
        loading: false,
      });
    }
  }

  @Action(LoadEmployeesCounts)
  async loadEmployeesCounts(ctx: StateContext<EmployeesStateModel>) {
    ctx.patchState({
      loading: true,
    });

    try {
      const counts = await lastValueFrom(
        this.http.get<EmployeeCounts>(
          `${environment.apiUrl}/v1/employees/counts`,
        ),
      );

      ctx.patchState({
        counts: {
          total: counts.total,
          active: counts.active,
          inactive: counts.inactive,
          admins: counts.admins,
          lastUpdated: new Date(),
          history: counts.history
            .map((historyItem) => ({
              ...historyItem,
              created: new Date(historyItem.created),
            }))
            .sort((a, b) => b.created.getTime() - a.created.getTime()),
        },
      });
    } catch (err) {
      throw err;
    } finally {
      ctx.patchState({
        loading: false,
      });
    }
  }

  private mapEmployees<T extends Employee>(values: T[]): T[] {
    return values.map((employee) => {
      return this.mapEmployee(employee);
    });
  }

  private mapEmployee<T extends Employee>(value: T): T {
    return {
      ...value,
      ...mapCreatedUpdated(value),
    };
  }
}
